<template>
  <div>
    <div class="filter-wrapper">
      <div class="link-wrapper">
        <router-link
          :to="{ name: 'CallsRecords'}">
          Records
        </router-link>
        <router-link :to="{ name: 'CallsLogs'}">
          Logs
        </router-link>
        <router-link
          v-if="computedIsAdmin"
          :to="{ name: 'CallsIssues'}">
          Issues
        </router-link>
        <router-link
          :to="{ name: 'CallsReport'}">
          Issues Report
        </router-link>
        <v-select
          v-model="dataSelectTeamsValue"
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Teams"
          :class="['call-select call-select-filter', dataSelectTeamsValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <call-pagination
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
      </div>
    </div>
    <div style="display: flex;">
      <div
        class="table-wrapper"
        style="flex-grow: 1">
        <b-table
          striped
          hover
          show-empty
          :tbody-tr-class="onRowClass"
          no-local-sorting
          :fields="dataMixinCallsFields"
          :items="dataCallsList"

          @row-clicked="(value) => onRecords(value, 'view')"
          @sort-changed="onSortTabel">
          <template #cell(teams)="data">
            {{ data.item.teams | joinName }}
          </template>
          <template #cell(calls)="data">
            {{ data.item.records.length || 0 }}
          </template>
        </b-table>
      </div>

      <div class="recoridng-wrapper">
        <call-call-list :obj-event="dataRecords" />
      </div>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { CALL_GET_USERS, TEAMS_GET_ALL } from '../../../store/types/actions.types';
import selectAllTeams from '../../../mixins/select/selectAllTeams';
import CallPagination from '../../../components/desktop/CallPagination';
import helper, { tabelCell } from '../../../service/helper';
import selectOperators from '@/mixins/select/selectOperators';
import callsTable from '@/mixins/table/callsTable';
import CallCallList from '@/components/desktop/recording/CallCallList';
import { mapGetters } from 'vuex';

export default {
  name: 'PageCalls',
  components: {
    CallCallList,
    CallPagination,
    BTable
  },
  mixins: [callsTable, selectAllTeams, selectOperators],
  data () {
    return {
      dataCallsList: [],
      dataDesc: null,
      dataOrderBy: null,
      dataObjPagination: {},
      dataRecords: {}
    };
  },
  computed: {
    ...mapGetters({
      computedIsOperator: 'auth/isOperator',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser',
      computedIsAdmin: 'auth/isAdmin'
    })
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = tabelCell();

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'role',
            'search',
            'teamId',
            'desc',
            'orderBy',
            'limit',
            'page'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'role':
                  this.dataSelectRoleValue = options[_key];
                  break;
                case 'search':
                  this.dataSearch = options[_key];
                  break;
                case 'teamId':
                  this.dataSelectTeamsValue = options[_key];
                  break;
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({ role = this.dataSelectRoleValue, search = this.dataSearch, teamId = this.dataSelectTeamsValue, desc = this.dataDesc, orderBy = this.dataOrderBy, limit = null, page = null, reset = false } = options) => {
        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const { payload: { list = [] }, misc: { paging = {} } } = await this.$store.dispatch(`call/${CALL_GET_USERS}`, {
          params: {
            role: role?.value,
            search,
            team_ids: [teamId?.id],
            desc,
            order_by: orderBy,
            limit: _limit,
            page: _page
          }
        });
        this.dataObjPagination = paging;
        this.dataCallsList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              role,
              search,
              teamId,
              desc,
              orderBy,
              limit: _limit,
              page: _page
            }))
          }
        }).catch(() => {});

        this.dataRecords = helper.getOneArray(this.dataCallsList);

        options = {};
      };
    })();

    const [{ payload: { list: listTeam = [] } }] = await Promise.all([
      this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
        params: {
          short: true
        }
      }),
      this.$_ajax()
    ]);

    this.dataSelectOptionsTeam = listTeam;
  },
  methods: {
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    onRecords (data) {
      console.log(data);
      this.dataRecords = data;
    },
    onRowClass (item, type) {
      if (!item || type !== 'row') return;
      if (item.id === this.dataRecords.id) return 'table-success';
    }
  }
};
</script>

<style lang="sass" scoped>
  .home
    display: flex
  .recoridng-wrapper
    flex: 0 0 265px
    margin-left: 33px

</style>
